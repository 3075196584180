import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const MouseMoveText = ({ children }) => {
  const [text, setText] = useState(children);
  const [animationInterval, setAnimationInterval] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseOver = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialCharacters = '!"#$%&\'()*+,-./:;<=>?+=';
    let iteration = 0;

    clearInterval(animationInterval);

    const newInterval = setInterval(() => {
      setText(prevText => {
        const newText = prevText
          .split('')
          .map((letter, index) => {
            if (index < iteration) {
              return children[index];
            }
            let chart = letters[Math.floor(Math.random() * 26)];
            if (/[a-z]/.test(letter)) {
              chart = chart.toLowerCase();
            }
            if (/\s/.test(letter)) {
              chart = letter;
            }
            if (/["#$%&'()*+,-./:;<=>?+=]/.test(letter)) {
              chart = specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
            }
            return chart;
          })
          .join('');

        return newText;
      });

      if (iteration >= children.length) {
        clearInterval(newInterval);
      }
      iteration += 1 / 3;
    }, 30);

    setAnimationInterval(newInterval);
  };

  useEffect(
    () => () => {
      clearInterval(animationInterval);
    },
    [animationInterval],
  );

  useEffect(() => {
    // Function to start the animation when the component is first mounted
    const startAnimationWithDelay = () => {
      setIsVisible(true); // Set the component as visible
      setTimeout(() => {
        handleMouseOver(); // Start the animation after the delay
      }, 2000); // Adjust the delay time in milliseconds (1 second in this example)
    };

    if (!isVisible) {
      // Start the animation with a delay when the component becomes visible
      startAnimationWithDelay();
    }

    return () => {
      clearInterval(animationInterval);
    };
  }, [isVisible, animationInterval]);

  return (
    <span
      onTouchStart={handleMouseOver}
      onMouseOver={handleMouseOver}
      onFocus={() => {}}
      onBlur={() => {}}>
      {text}
    </span>
  );
};

MouseMoveText.propTypes = {
  children: PropTypes.string,
};

export default MouseMoveText;
